import Slider from "react-slick"
import { useGetStaticPageByNameQuery /*, type IMainCTA */ } from "state/fetch/admin"
import { Link } from "react-router-dom"

const SLIDES_TO_SHOW = 1
const SlidingBannerItem = ({
  item
}: {
  item: { url: string | null; text: string; ctaText: string | null; TextColor: string | null }
}) => {
  return (
    <Link
      to={item.url ?? "/"}
      target={(item.url ?? "/").startsWith("http") ? "_blank" : "_self"}
      rel="noopener noreferrer"
      className={`flex cursor-pointer h-8 px-5 mx-2 gap-2 items-center text-[13px] leading-none flex-nowrap`}
      style={{ width: "auto", color: item.TextColor ?? "#FDFDFD" }}>
      <div className="whitespace-nowrap">{item.text}</div>
      {item.ctaText && <div className="text-[#B3B3B8] ml-1 underline underline-offset-4">{item.ctaText}</div>}
    </Link>
  )
}

const SlidingBanner = () => {
  const { data: staticPage } = useGetStaticPageByNameQuery("SlidingBanner")

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    variableWidth: true,
    pauseOnHover: true
  }

  if (!staticPage) return null
  const { MainCTA } = staticPage ?? {}

  const list = (MainCTA ?? []).filter((item) => item.text)
  if (!list.length) return null
  return (
    <div className={`flex h-8 w-full bg-[#1A192B] z-[6] items-center fixed top-0`}>
      <Slider {...settings}>
        {list.map((item) => (
          <SlidingBannerItem key={item.id} item={item} />
        ))}
      </Slider>
    </div>
  )
}

export default SlidingBanner
