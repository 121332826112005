import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import MailChimpForm from "./MailChimpForm"
import withMailChimpContainer from "components/MailChimpContainer/withMailChimpContainer"
import { useGetFootersQuery, IFooters } from "state/fetch/admin"

const LinkWrapper = ({ link }: { link: IFooters }) => {
  if (link.NewWindow)
    return (
      <a href={link.URL} className="leading-[160%]" target="_blank" rel="noopener noreferrer">
        <p className={`my-[5px] opacity-70 text-base footer-link`}>{link.Text}</p>
      </a>
    )
  return (
    <Link key={link.id} className="leading-[160%]" to={link.URL}>
      <p className={`my-[5px] opacity-70 text-base main-bg footer-link`}>{link.Text}</p>
    </Link>
  )
}

const Footer = ({ darkStyle = false }: { darkStyle?: boolean }) => {
  const { data = [], isSuccess } = useGetFootersQuery()
  const [isEmailNeeded, setIsEmailNeeded] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === "/grants" || location.pathname === "/grow" || location.pathname === "/") {
      setIsEmailNeeded(true)
    } else {
      setIsEmailNeeded(false)
    }
  }, [location.pathname, setIsEmailNeeded])

  const MailForm = withMailChimpContainer(MailChimpForm, import.meta.env.VITE_APP_MAILCHIMP_ID ?? "", darkStyle)

  return (
    <div className={`shrink-0 pt-[50px] main-bg ${darkStyle && "isDark"}`}>
      <div className="max-w-[1180px] mx-auto my-auto w-full">
        <div className="px-[1.75rem]">
          {isEmailNeeded && (
            <div className="flex flex-col items-center md:flex-row justify-between" id="contact-form">
              <div
                className="text-2xl text-transparent bg-clip-text bg-gradient-to-r from-[#0B6FF9] to-[#FB71AE] md:mr-6"
                id="contact-form">
                Sign up for our amazing newsletter.
              </div>
              <MailForm />
            </div>
          )}
          {isSuccess && !!data.length && (
            <div className="flex justify-between mt-[5.25rem] flex-wrap md:flex-nowrap">
              <div className="w-full md:w-fit">
                <div className="w-[65px] h-[30px] footer-logo"></div>
              </div>
              <div id="company-links" className="flex flex-col order-3 md:order-2 w-[50%] md:w-[20%]">
                <p className={`font-normal mb-8 mt-0 leading-[160%] footer-link`}>Company</p>
                <div className={`flex flex-col`}>
                  {data
                    .filter((link: IFooters) => link.Category === "Company")
                    .map((link: IFooters) => (
                      <LinkWrapper key={link.id} link={link} />
                    ))}
                </div>
              </div>
              <div id="help-links" className="flex flex-col order-3 md:order-2 w-[50%] md:w-[20%]">
                <p className="font-normal mb-8 mt-0 leading-[160%] footer-link">Help</p>
                <div className="flex flex-col">
                  {data
                    .filter((link: IFooters) => link.Category === "Help")
                    .map((link: IFooters) => (
                      <LinkWrapper key={link.id} link={link} />
                    ))}
                </div>
              </div>
              <div id="information-links" className="flex flex-col order-4 md:order-3 w-full md:w-[20%] mt-8 md:mt-0">
                <p className="font-normal mb-8 mt-0 leading-[160%] footer-link">Information</p>
                <div className="flex flex-col">
                  {data
                    .filter((link: IFooters) => link.Category === "Information")
                    .map((link: IFooters) => (
                      <LinkWrapper key={link.id} link={link} />
                    ))}
                </div>
              </div>
              <div
                id="slinks"
                className="flex flex-col w-full max-w-[400px] my-10 md:my-0 md:w-[20%] order-2 md:order-4">
                <p className="first-letter:font-normal mb-8 mt-0 leading-[160%] footer-link">Poolz Finance</p>
                <div className="flex flex-row gap-x-10">
                  {data
                    .filter((link: IFooters) => link.Category === "Social")
                    .map((link: IFooters) => (
                      <a key={link.id} href={link.URL} target="_blank" rel="noopener noreferrer" aria-label={link.Text}>
                        <div className={`${link.Text}-icon w-[24px] h-[24px]`} />
                      </a>
                    ))}
                </div>
                <p className="first-letter:font-normal mb-8 mt-14 leading-[160%] footer-link">Poolz Boost</p>
                <div className="flex flex-row gap-x-10">
                  {data
                    .filter((link: IFooters) => link.Category === "Boost_Social")
                    .map((link: IFooters) => (
                      <a key={link.id} href={link.URL} target="_blank" rel="noopener noreferrer" aria-label={link.Text}>
                        <div className={`${link.Text}-icon w-[24px] h-[24px]`} />
                      </a>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="text-xs text-[#878693] py-[3.25rem]">© Copyright 2024 Poolz. All rights reserved.</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
