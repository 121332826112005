import { getAddress } from "@ethersproject/address"

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// remove hash in url without affecting history or forcing reload
export const removeHash = () => {
  history.replaceState("", document.title, window.location.pathname + window.location.search)
}

export const goToAnchor = (hash: string, affectHistory: boolean) => {
  if (affectHistory) {
    window.location.hash = hash
  } else {
    window.location.replace(`#${hash}`)
  }
}

export const generateUniqueId = () => {
  const array = new Uint32Array(2)
  crypto.getRandomValues(array)
  const timestamp = new Date().getTime()
  const randomNumber = array[0] % 10000
  return `${timestamp}-${randomNumber}`
}

export const shortenText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    text = text.substring(0, maxLength)
    text = text.trim() + "..."
  }
  return text
}
interface RangeObject {
  Amount: string
  CoolDownPeriod: string
  id: number
}

export const findClosestAmount = (arr: RangeObject[], targetAmount: number) => {
  const parsedAmounts = arr.map((obj) => {
    const range = obj.Amount.split("-").map((part) => parseInt(part.replace(/\D/g, ""), 10))
    return range.map((num) => (isNaN(num) ? Infinity : num))
  })
  const distances = parsedAmounts.map((range) => {
    const [min, max] = range
    if (targetAmount < min) {
      return min - targetAmount
    } else if (targetAmount > max) {
      return targetAmount - max
    } else {
      return 0
    }
  })
  return arr.at(distances.indexOf(Math.min(...distances)))
}
