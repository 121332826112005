import { Picture } from "pages/ProjectDetails/types"
import { IconInfo, FormatInfo } from "../components/IdoCard/ido-interface"

export const apiImages = import.meta.env.VITE_APP_ADMIN + "cdn-cgi/image/format=auto/uploads/"

export const whichImage = {
  investor: "Investor",
  projectLogo: "projectLogo"
}

interface IImagesComp {
  iconInfo: IconInfo
  alt: string
  height?: string
  width?: string
  isCircle?: boolean
}
interface ICDNOptions {
  width?: number
  fit?: "contain" | "cover" | "scale-down" | "crop" | "pad"
}

export const ImagesComp = ({ iconInfo, alt, height, width, isCircle = false }: IImagesComp) => {
  return (
    <img
      src={tryThumbnailImage(iconInfo)}
      alt={alt}
      style={{ height: height, width: width }}
      className={`${isCircle ? "rounded-[50%]" : "rounded-none"} object-contain`}
    />
  )
}
const baseImage = (formatInfo: FormatInfo, options?: ICDNOptions) => {
  if (!formatInfo.hash || !formatInfo.ext) {
    console.error("formatInfo.hash or formatInfo.ext is undefined")
    return apiImages
  }

  const pattrenString: string[] = []
  if (options) {
    for (const [key, value] of Object.entries(options)) {
      pattrenString.push(`${key}=${value}`)
    }
  }
  return (
    (pattrenString.length ? apiImages.replace(/=auto/, "=auto," + pattrenString.join(",")) : apiImages) +
    formatInfo.hash +
    formatInfo.ext
  )
}

export const tryThumbnailImage = (iconInfo: IconInfo, options?: ICDNOptions) => {
  return iconInfo?.formats?.thumbnail ? baseImage(iconInfo.formats.thumbnail, options) : baseImage(iconInfo, options)
}

export const getImageUrl = (picture: Picture) => {
  return apiImages + (picture.url ?? "").replace(/^\/uploads\//, "")
}

export const ImageWrapCDN: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement> & { maxwidth: number; info: IconInfo; cdnoptions?: ICDNOptions }
> = (props) => {
  const { maxwidth, info, cdnoptions, ...rest } = props
  const height = Math.floor((info.height / info.width) * maxwidth)

  return (
    <img
      src={tryThumbnailImage(info, cdnoptions)}
      style={{ maxWidth: maxwidth }}
      width={maxwidth}
      height={height}
      {...rest}
    />
  )
}
