import { useState } from "react"
import { tryThumbnailImage } from "utils/api-images"
import { useGetBuyPoolzLinksQuery, IBuyPoolzLinks } from "state/fetch/admin"
import { BuySelectArrow } from "assets/svg/buySelectArrow"

const BuySelect = ({ size }: { size?: string }) => {
  const { data = [] } = useGetBuyPoolzLinksQuery()
  const [showDropdown, setShowDropdown] = useState(false)

  if (!data.length) return null

  return (
    <div className="relative">
      <div
        id="buy-poolz-btn"
        className={`flex items-center justify-between border-[1px] border-solid w-[155px] px-2 rounded-md cursor-pointer transition-all ${
          size ? size : "h-[2.1rem]"
        }
        `}
        onClick={() => setShowDropdown(!showDropdown)}>
        <div className="w-full flex items-center text-xs">
          Buy{" "}
          <span className="text-[10px] bg-black bg-gradient-to-r from-[#0666EA] to-[#FB71AE] text-[#FDFDFD] rounded px-1 ml-1">
            $POOLX
          </span>
        </div>
        <BuySelectArrow showDropdown={showDropdown} />
      </div>
      {showDropdown && (
        <div
          id="buy-poolz-info"
          className="absolute flex flex-col items-center justify-between border-[1px] border-solid w-[155px] mr-4 px-2 rounded-md cursor-pointer divide-y">
          {data.map((link: IBuyPoolzLinks) => (
            <a
              key={link.id}
              className={`flex items-center justify-between py-2 w-full hover:opacity-50 text-xs`}
              href={link.URL}
              target="_blank"
              rel="noopener noreferrer">
              {link.Name}
              {link?.Icon && (
                <img src={tryThumbnailImage(link.Icon[0])} alt={link.Name} className="max-w-[16px] max-h-[16px]" />
              )}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default BuySelect
