import { createAction } from "@reduxjs/toolkit"
import { EAccountStatus } from "models/enums/EAccountStatus"

export type PopupContent = {
  txn: {
    hash: string
    success: boolean
    summary?: string
  }
}

export enum ApplicationModal {
  WALLET,
  SETTINGS,
  SELF_CLAIM,
  ADDRESS_CLAIM,
  CLAIM_POPUP,
  MENU,
  DELEGATE,
  VOTE,
  IDO_WHITELIST_JOIN
}

export const setOpenModal = createAction<ApplicationModal | null>("application/setOpenModal")
export const setAccountStatus = createAction<EAccountStatus>("application/setAccountStatus")
