import { NavLink, useLocation } from "react-router-dom"
import { navItemsData } from "./constants"

const NavMenu = () => {
  const location = useLocation()
  return (
    <div className="my-0 h-full">
      <ul id="header-links" className="h-full flex items-center font-bold">
        {navItemsData.map((item) => (
          <li
            key={item.text}
            className={`h-3/5 mt-auto inline-block pb-auto mx-4 text-sm leading-sm transition-all ${
              location.pathname.startsWith("/" + item.path.split("/")[1])
                ? "pb-auto border-b border-solid active-line"
                : ""
            }`}>
            <NavLink to={item.path}>{item.text}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavMenu
