import { lazy } from "react"

const Lobby = lazy(() => import("pages/Lobby/Lobby"))
const InfoPage = lazy(() => import("pages/InfoPage/InfoPage"))
const Projects = lazy(() => import("pages/Projects/Projects"))
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"))
const Leaderboard = lazy(() => import("pages/leaderboard/Leaderboard"))
const ProjectDetails = lazy(() => import("pages/ProjectDetails/ProjectDetails"))
const Grants = lazy(() => import("pages/Grants/Grants"))
const Media = lazy(() => import("pages/Media/Media"))
const StakingV2 = lazy(() => import("pages/StakingV2/Staking"))
const AboutUs = lazy(() => import("pages/AboutUs/AboutUs"))
const Grow = lazy(() => import("pages/Grow/Grow"))
const GrowDetails = lazy(() => import("pages/GrowDetails/GrowDetails"))
const NotFound = lazy(() => import("pages/NotFound/NotFound"))

export const routes = [
  {
    path: "/",
    component: Lobby,
    darkStyle: true
  },
  {
    path: "/project-details/about/:id",
    component: ProjectDetails
  },
  {
    path: "/leaderboard",
    component: Leaderboard
  },
  {
    path: "/lock-poolx/:subCategory?",
    component: StakingV2
  },
  {
    path: "/dashboard/:subCategory?/:subCategory2?",
    component: Dashboard
  },
  {
    path: "/grants",
    component: Grants,
    darkStyle: true
  },
  {
    path: "/media-kit",
    component: Media
  },
  {
    path: "/projects",
    component: Projects
  },
  {
    path: "/privacy",
    component: InfoPage
  },
  {
    path: "/terms-conditions",
    component: InfoPage
  },
  {
    path: "/lock-poolz-terms-conditions",
    component: InfoPage
  },
  {
    path: "/aboutus",
    component: AboutUs,
    darkStyle: true
  },
  {
    path: "/boost",
    component: Grow,
    darkStyle: true
  },
  {
    path: "/boost/:id",
    component: GrowDetails,
    darkStyle: true
  },
  {
    path: "*",
    component: NotFound
  }
]
