export const BuySelectArrow = ({ showDropdown }: { showDropdown: boolean }) => (
  <svg
    className={`ml-4 transition-all ${showDropdown ? "rotate-180" : "rotate-0"}`}
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0.97168 1L6.20532 6L11.439 1" />
  </svg>
)
