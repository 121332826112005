export enum EAccountStatus {
  /**
   * Grey color
   */
  Checking,
  /**
   * Green color
   */
  Verified,
  /**
   * Red color
   */
  NotVerified,
  /**
   * Orange color
   */
  Processing
}
