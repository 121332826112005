export const MobileMenuButton = ({ showMenu, setShowMenu }: { showMenu: boolean; setShowMenu: Function }) => {
  return (
    <button
      className={`flex tb:hidden bg-transparent z-[20] rounded-lg w-[28px] min-w-[28px] h-8 flex-col items-end p-1 outline-0 border-0 justify-evenly duration-500 mobileMenuButton`}
      data-testid="mobile-menu-button"
      aria-label="menu"
      onClick={() => setShowMenu(!showMenu)}>
      <span
        className={`h-[2px] w-[20px] block rounded-lg transition-all duration-500 ${
          showMenu ? "absolute rotate-45 !bg-[#fff]" : "relative rotate-0 !bg-[#000]"
        }`}></span>
      <span
        className={`h-[2px]  block rounded-lg  transition-all duration-500 ${
          showMenu ? "absolute rotate-[135deg] w-[20px] !bg-[#fff]" : "relative rotate-0 w-[20px] !bg-[#000]"
        }`}></span>
    </button>
  )
}
