import React from "react"
import { useWalletModalToggle } from "state/application/hooks"

import { useThePoolz } from "@poolzfinance/reacthelper"

const Web3Status: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const thePool = useThePoolz()
  const { account } = thePool

  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return null
  }
  return (
    <button
      {...props}
      className={`md:ml-[5px] md:w-[140px] min-h-[2.1rem] cursor-pointer rounded-md border border-solid border-slate-50/20 text-xs ${props.className}`}
      onClick={toggleWalletModal}>
      {props.children}
    </button>
  )
}

export default Web3Status
