import { useEffect, useState } from "react"
import Loader from "components/Loader/index"
import { EmailFormFields } from "react-mailchimp-subscribe"

interface IMailChimpForm {
  status?: "error" | "success" | "sending"
  message?: string | Error
  onValidated?: (formData: EmailFormFields) => void
  darkStyle?: boolean
}

const MailChimpForm = ({ status, onValidated, darkStyle }: IMailChimpForm) => {
  const [email, setEmail] = useState<string>("")
  const [displayError, setDisplayError] = useState<boolean>(false)

  useEffect(() => {
    if (status === "success") setEmail("")
  }, [status])

  const isSubscribeEnabled = () => {
    if (status) return false
    return true
  }

  const isEmailValid = (email: string) => {
    const emailPattern = /^[\w.-]+@([\w-]+\.)+[\w-]+$/
    return emailPattern.test(email)
  }

  const handleSubscribe = () => {
    setDisplayError(false)
    if (!email.length) return
    if (!isEmailValid(email)) {
      setDisplayError(true)
      return
    }
    if (onValidated)
      onValidated({
        EMAIL: email
      })
  }

  const getButton = () => {
    if (status === "error")
      return (
        <span role="img" aria-label="Error">
          ❌
        </span>
      )
    if (status === "sending") return <Loader size="24px" stroke="rgb(255, 255, 255)" className="mx-auto animate-spin" />
    if (status === "success")
      return (
        <span role="img" aria-label="Success">
          ✅
        </span>
      )
    return "Subscribe"
  }

  return (
    <div>
      <div>
        {displayError && <span style={{ color: "red" }}>Incorrect Email</span>}
        <div
          className={`mt-6 md:mt-0 flex items-center  w-full md:w-[30rem] h-[54px] rounded-md ${
            darkStyle ? "bg-[#1A192B]" : "bg-[#E8E7E6]"
          }`}>
          <input
            type="text"
            placeholder="Your email address ..."
            className={`w-9/12 h-[54px] rounded-md pl-5 text-base leading-4 border-0 focus:outline-none ${
              darkStyle ? "bg-[#1A192B] text-[#b3b3b8]" : "bg-[#E8E7E6] text-[#676671]"
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            className="bg-[#E70466] w-32 h-[40px] mr-2 rounded-md order-0"
            onClick={handleSubscribe}
            disabled={!isSubscribeEnabled()}>
            <span className="text-xs leading-3 text-white">{getButton()}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MailChimpForm
