import { useState } from "react"
import { DropdownObjectType } from "./types"
import { DropdownMenuObjects } from "./constants"
import { generateUniqueId } from "utils"
import { Link } from "react-router-dom"

export const DropdownItem = (item: DropdownObjectType) => {
  const [active, setActive] = useState(false)

  return (
    <Link
      to={item.url}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="dropdown-menu-link"
      className="group w-full flex items-center p-3 rounded-md hover:bg-[#3BFFAD]/[.1]"
      key={generateUniqueId()}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}>
      <img className="mr-4 w-5 h-5 bg-contain bg-no-repeat" src={active ? item.imageActive : item.image} alt="icon" />
      <p className="text-sm leading-3 cursor-pointer text-white group-hover:text-[#3BFFAD]">{item.text}</p>
    </Link>
  )
}

const DropdownMenu = () => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <div
      className="rounded-[50%] inline-block cursor-pointer md:mr-4  min-w-[40px] md:min-w-[50px] h-[50px] relative bg-no-repeat bg-center bg-[length:32px] dropdownMenu"
      data-testid="DropdownMenuTestId"
      onClick={() => setShowDropdown(!showDropdown)}>
      <div
        data-testid="DropdownMenuTestContent"
        className={`flex flex-col absolute left-0 min-w-[220px] top-[101%] bg-[#010013] rounded-md p-5 transition-opacity ease-in-out duration-200 border-[1px] border-solid border-[#272636]  ${
          showDropdown ? "visible opacity-1" : "invisible opacity-0"
        }`}>
        <div>{DropdownMenuObjects.map((item: DropdownObjectType) => DropdownItem(item))}</div>
      </div>
    </div>
  )
}

export default DropdownMenu
