import { configureStore } from "@reduxjs/toolkit"
//import { save, load } from "redux-localstorage-simple"

import application from "./application/reducer"
import { admin } from "./fetch/admin"
import { ipapi } from "./fetch/ipapi"

//const PERSISTED_KEYS: string[] = ["application"]

const store = configureStore({
  reducer: {
    application,
    [admin.reducerPath]: admin.reducer,
    [ipapi.reducerPath]: ipapi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(/*{ thunk: false, serializableCheck: false }*/).concat(
      //save({ states: PERSISTED_KEYS }),
      admin.middleware,
      ipapi.middleware
    )
  //preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true })
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
