import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, AppState } from "../index"
import { ApplicationModal, setOpenModal, setAccountStatus } from "./actions"
import { EAccountStatus } from "models/enums/EAccountStatus"

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useAccountStatus() {
  const dispatch = useDispatch<AppDispatch>()
  const colors = ["#b3b3b8", "#00A560", "#F3544A", "#FF891D"]
  const status = ["Checking..", "Verified", "Not Verified", "Processing"]

  const accountStatus = useSelector((state: AppState) => state.application.accountStatus)
  return {
    accountStatus,
    colorStatus: colors[accountStatus],
    textStatus: status[accountStatus],
    dispatch: useCallback((status: EAccountStatus) => dispatch(setAccountStatus(status)), [dispatch])
  }
}
