import ReactDOM from "react-dom"
import CloseIcon from "@mui/icons-material/Close"
import React, { useEffect, useRef, useState } from "react"

const ModalWindow = ({
  children,
  isOpen,
  closeModal,
  parentSelector
}: {
  children: React.ReactNode
  isOpen: boolean
  closeModal?: () => void
  parentSelector: string
}) => {
  const [showModalOnTop, setShowModalOnTop] = useState<boolean>()
  const modalContent = useRef<HTMLDivElement>(null)
  const parentElement = document.querySelector(parentSelector) as HTMLElement

  useEffect(() => {
    if (parentElement) {
      parentElement.style.overflowY = isOpen ? "hidden" : "auto"
      document.body.style.overflowY = isOpen ? "hidden" : "auto"
    }
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      parentElement.style.position = isOpen ? "fixed" : "static"
      parentElement.style.height = isOpen ? "100vh" : "auto"
      parentElement.style.overflow = isOpen ? "hidden" : "auto"
    }
    return () => {
      if (parentElement) {
        parentElement.style.overflowY = "auto"
        document.body.style.overflowY = "auto"
      }
      if (isSafari) {
        parentElement.style.position = "static"
        parentElement.style.height = "auto"
        parentElement.style.overflow = "auto"
      }
    }
  }, [isOpen, parentSelector, parentElement])

  const checkModalSize = () => {
    const modalRect = modalContent?.current?.getBoundingClientRect()
    if (!modalRect) {
      return
    }
    const { height } = modalRect
    if (
      height + 20 > parentElement.clientHeight ||
      (height + 20 > window.innerHeight && parentElement.clientHeight > window.innerHeight)
    ) {
      setShowModalOnTop(true)
    } else {
      setShowModalOnTop(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", checkModalSize)
    checkModalSize()
    return () => {
      window.removeEventListener("resize", checkModalSize)
    }
  }, [parentElement, modalContent, checkModalSize])

  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div className="fixed w-full h-full top-0 left-0 bottom-0 right-0 z-[20] backdrop-blur-[20px] bg-neutral-500/20 overflow-y-scroll">
      <div
        ref={modalContent}
        className={`${
          showModalOnTop ? "top-[20px]" : "top-[50%] -translate-y-2/4"
        } absolute left-[50%] -translate-x-[50%]`}>
        <div className="z-30 border rounded-lg border-solid bg-[#fdfdfd]">
          <div className="relative">
            {closeModal && (
              <button
                className="absolute top-2 right-2 p-1 rounded-full hover:bg-[#d8d8d8] cursor-pointer z-30"
                onClick={(e) => {
                  const elem = e.target as HTMLElement
                  const thisButton = elem.closest("button")
                  thisButton?.setAttribute("disabled", "true")
                  closeModal()
                }}>
                <CloseIcon />
              </button>
            )}
            <div className="flex items-center p-5 min-w-[310px] min-h-[100px]">{children}</div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(parentSelector) as HTMLElement
  )
}

export default ModalWindow
