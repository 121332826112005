import { NavLink } from "react-router-dom"
import Web3Status from "../Web3Status"

export default function MobileMenu({ closeNav }: { closeNav: () => void }) {
  return (
    <>
      <div
        id="myNav"
        className="flex tb:hidden flex-col justify-between bg-black fixed top-0 overflow-hidden text-center z-[10] transition-all duration-500 h-[100vh] w-[100vw] mobileMenu"
        data-testid="mobile-menu-id"
        style={{ backdropFilter: "blur(10px) saturate(90%) brightness(90%)" }}>
        <div
          style={{ background: "linear-gradient(to bottom, #000000 0%,rgba(125,185,232,0) 100%)" }}
          className="h-[80px] w-full absolute z-[1] top-[78px] pointer-events-none"
        />
        <div className="mt-[78px] h-full flex flex-col justify-between overflow-scroll pb-[10vh]">
          <div className="flex flex-col justify-around relative w-full my-8 px-5">
            <NavLink
              to="/"
              onClick={closeNav}
              className={({ isActive }) =>
                "w-fit m-0 no-underline text-[36px] text-[#FDFDFD] focus:text-[#17b9fe] hover:text-[#17b9fe] block transition-all duration-200 my-3" +
                (isActive ? " text-[#f1f1f1]" : "")
              }>
              Lobby
            </NavLink>
            <NavLink
              to="/leaderboard"
              onClick={closeNav}
              className={({ isActive }) =>
                "w-fit m-0 no-underline text-[36px] text-[#FDFDFD] focus:text-[#17b9fe] hover:text-[#17b9fe] block transition-all duration-200 my-3" +
                (isActive ? " text-[#f1f1f1]" : "")
              }>
              Leaderboard
            </NavLink>
            <NavLink
              to="/dashboard/MyTokens"
              onClick={closeNav}
              className={({ isActive }) =>
                "w-fit m-0 no-underline text-[36px] text-[#FDFDFD] focus:text-[#17b9fe] hover:text-[#17b9fe] block transition-all duration-200 my-3" +
                (isActive ? " text-[#f1f1f1]" : "")
              }>
              Dashboard
            </NavLink>
            <NavLink
              to="/lock-poolx"
              onClick={closeNav}
              className={({ isActive }) =>
                "w-fit m-0 no-underline text-[36px] text-[#FDFDFD] focus:text-[#17b9fe] hover:text-[#17b9fe] block transition-all duration-200 my-3" +
                (isActive ? " text-[#f1f1f1]" : "")
              }>
              Lock $POOLX
            </NavLink>
            <NavLink
              to="/boost"
              onClick={closeNav}
              className={({ isActive }) =>
                "w-fit m-0 no-underline text-[36px] text-[#FDFDFD] focus:text-[#17b9fe] hover:text-[#17b9fe] block transition-all duration-200 my-3" +
                (isActive ? " text-[#f1f1f1]" : "")
              }>
              Poolz Boost
            </NavLink>
          </div>
          <div className="mb-6 px-5">
            <div className="h-10 text-[20px] flex items-center text-[#FDFDFD]">
              <Web3Status id="connect-wallet-mobile" className="!w-full max-w-[500px] !mx-auto md:x-auto">
                Connect your Wallet
              </Web3Status>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
