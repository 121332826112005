import { useEffect, useState } from "react"
import Web3Status from "components/Web3Status"
import { NavLink, useLocation } from "react-router-dom"
import MobileMenu from "components/Header/MobileMenu"
import { useGetPageInfoQuery } from "state/fetch/admin"
import DropdownMenu from "components/Header/components/DropdownMenu/DropdownMenu"
import NavMenu from "./components/NavMenu/NavMenu"
import { MobileMenuButton } from "components/Header/components/MobileButton/MobileMenuButton"
import { AccountInfo } from "components/Header/components/AccountInfo/AccountInfo"
import BuySelect from "components/Header/components/BuySelect/BuySelect"
import SlidingBanner from "components/Header/components/SlidingBanner/SlidingBanner"

const Header = ({ darkStyle }: { darkStyle?: boolean }) => {
  const [showMenu, setShowMenu] = useState(false)
  const navigation = useLocation()
  const { data: PageInfo } = useGetPageInfoQuery()

  const closeNav = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showMenu])

  useEffect(() => {
    if (!PageInfo) return
    const Title = PageInfo.find((page) => page.Link === navigation.pathname)?.Title
    if (Title) document.title = Title
  }, [navigation, PageInfo])

  return (
    <>
      <div
        className={`header bg-transparent top-0 w-full inline-block h-[4.875rem] z-[6] border-b-[1px] border-[#f0f0f1] border-solid
        ${showMenu ? "showMenu" : ""} 
        ${darkStyle ? "isDark" : ""}
        ${navigation.pathname === "/" ? "top-7" : ""}
      `}
        data-testid="header-id">
        <div className="py-4% px-2 tb:px-[4%] items-center  md:py-0 h-full flex overflow-visible md:grid headerGrid">
          <MobileMenu closeNav={closeNav} />

          <div className={`flex md:min-w-[130px] z-[20]`}>
            <DropdownMenu />
            <NavLink to="/" aria-label="home">
              <div className="logo min-w-[50px] w-[60px] h-full headerLogo" />
            </NavLink>
          </div>

          <div className="flex tb:hidden items-center w-full text-right rounded-xl justify-center">
            <AccountInfo />
          </div>
          <MobileMenuButton showMenu={showMenu} setShowMenu={setShowMenu} />
          <div className="hidden tb:flex flex-col-reverse tb:flex-row w-full h-full items-end tb:items-center tb:pl-[0.5rem]">
            <NavMenu />
            <div className="flex-1">
              <div className="flex items-center w-full justify-end gap-x-2 px-3">
                <div className="flex justify-center">
                  <BuySelect />
                </div>
                <AccountInfo />
                <div className="flex justify-center">
                  <Web3Status id="connect-wallet">Connect Wallet</Web3Status>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {navigation.pathname === "/" && <SlidingBanner />}
    </>
  )
}

export default Header
