import { useEffect, useState } from "react"
import Arrow from "assets/images/arrow-top-white.svg"

const handleUpButton = () => {
  window.scrollTo(0, 0)
}

export default function BtnUp() {
  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <button
      className={`fixed w-12 h-12 bg-[#fb71ae] z-30 cursor-pointer bottom-[60px] right-[40px] rounded-full opacity-30 hover:opacity-100 transition-all flex justify-center items-center  ${
        scroll > 300 ? "visible" : "hidden"
      } `}
      onClick={handleUpButton}>
      <img src={Arrow} className="h-[30px]" alt="arrow" />
    </button>
  )
}
