import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const ipapi = createApi({
  reducerPath: "ipapi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://ipapi.co/" }),
  endpoints: (build) => ({
    getCountry: build.query<string, void>({
      query: () => ({
        url: `country`,
        responseHandler: (response) => response.text()
      })
    })
  })
})

export const { useGetCountryQuery } = ipapi
