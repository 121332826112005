import React from "react"
import { isTimeSame } from "components/Header/header-functions"
import { useGetCurrentTime } from "@poolzfinance/api4"

export const TimeWarning = () => {
  const { data, error } = useGetCurrentTime()
  const [timeWarning, setTimeWarning] = React.useState(false)

  React.useEffect(() => {
    if (!error && data && data.time) setTimeWarning(isTimeSame(data.time))
  }, [error, data])

  if (!timeWarning) return null

  return (
    <div className="fixed top-0 left-0 w-full flex flex-col items-center p-[20px] z-[11] backdrop-blur-sm h-fit bg-[#0000007d]">
      <div className="flex flex-col items-center text-white">
        <p className="my-[5px]">Your device time is desynchronised.</p>
        <p className="my-[5px]">POOLZ needs your device time to be synchronized for proper site functioning.</p>
        <p className="my-[5px]">Please synchronize your device time and restart browser.</p>
      </div>
      <button
        data-testid="time-warning-accept"
        className="px-4 py-2 bg-black rounded mt-4 cursor-pointer border-solid border-[#17b9fe] border-2 text-[#17b9fe] text-center mx-[10px] hover:text-white"
        onClick={() => setTimeWarning(false)}>
        Accept
      </button>
    </div>
  )
}
