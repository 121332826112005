import { createReducer } from "@reduxjs/toolkit"
import { PopupContent, ApplicationModal, setOpenModal, setAccountStatus } from "./actions"
import { EAccountStatus } from "models/enums/EAccountStatus"

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly popupList: PopupList
  readonly openModal: ApplicationModal | null
  readonly accountStatus: EAccountStatus
}

const initialState: ApplicationState = {
  popupList: [],
  openModal: null,
  accountStatus: EAccountStatus.Checking
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setOpenModal, (state, action) => {
    state.openModal = action.payload
  })
  builder.addCase(setAccountStatus, (state, action) => {
    state.accountStatus = action.payload
  })
})
