import { Component, ErrorInfo, ReactNode } from "react"
import { useThePoolz } from "@poolzfinance/reacthelper"
import type { IThePoolzInterface as ThePoolzInterface } from "@poolzfinance/reacthelper"
import LoggerErrors from "./LoggerErrors"
import Maintenance from "./Maintenance"

interface Props {
  children?: ReactNode
  thePoolz?: ThePoolzInterface
}

interface State {
  hasError: boolean
}
interface LoggerProps {
  [k: string]: string | number
}

type EffectiveConnectionType = "2g" | "3g" | "4g" | "slow-2g"
type ConnectionType = "bluetooth" | "cellular" | "ethernet" | "mixed" | "none" | "other" | "unknown" | "wifi" | "wimax"
interface NetworkInformation extends EventTarget {
  readonly type?: ConnectionType
  readonly effectiveType?: EffectiveConnectionType
  readonly downlinkMax?: number
  readonly downlink?: number
  readonly rtt?: number
  readonly saveData?: boolean
  onchange?: EventListener
}

type NavigatorExtend = Navigator & {
  connection?: NetworkInformation
}

const FLAG_NAME = "loadingChunkErrorTime"
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorMessage: LoggerProps = {
      /* GIT_HASH: import.meta.REACT_APP_GIT_HASH || "", */
      performance: performance.now(),
      userAgent: window.navigator.userAgent,
      Wallet: this.props.thePoolz?.account ?? "null",
      ChainId: this.props.thePoolz?.chainId ?? "null",
      Location: window.location.toString(),
      Error: error.message,
      Stack: errorInfo.componentStack
    }

    if (this.handleLoadingChunkError(error.message)) return window.location.reload()
    this.handleNetworkConnection(errorMessage)

    if (window.location.hash === "#DebugMode") {
      console.error(errorMessage)
    } else {
      await LoggerErrors(errorMessage)
    }
  }

  private handleLoadingChunkError(errorMessage: string) {
    const loadingChunkErrorRegex = [
      // /Loading chunk.*failed/, // This is old regex used in CRA
      /Importing a module script failed/,
      /Failed to fetch dynamically imported module/,
      /Unable to preload CSS for .*\.css/,
      /error loading dynamically imported module/
    ]

    if (loadingChunkErrorRegex.some((reg) => reg.test(errorMessage))) {
      const now = Date.now()
      const loadingChunkErrorTime = localStorage.getItem(FLAG_NAME)
      if (loadingChunkErrorTime && parseInt(loadingChunkErrorTime) + 60000 > now) {
        localStorage.removeItem(FLAG_NAME)
      } else {
        localStorage.setItem(FLAG_NAME, now.toString())
        return true
      }
    }
  }

  private handleNetworkConnection(errorMessage: LoggerProps) {
    const navigator: NavigatorExtend = window.navigator
    if ("connection" in navigator) {
      const { type, effectiveType, downlink } = navigator.connection ?? {}
      if (type) errorMessage["ConnectionType"] = type
      if (effectiveType) errorMessage["EffectiveType"] = effectiveType
      if (downlink) errorMessage["Downlink"] = downlink
    }
  }

  public render() {
    if (this.state.hasError) {
      return <Maintenance />
    }

    return this.props.children
  }
}

const withMyHook = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: Props) => {
    const thePoolz = useThePoolz()
    return <WrappedComponent {...(props as P)} thePoolz={thePoolz} />
  }
}

export default withMyHook(ErrorBoundary)
