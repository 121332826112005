import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe"

const withMailChimpContainer = (Form: any, mailChimpId: string, darkStyle?: boolean) => () => {
  const chimpUrl = `https://poolzdefi.us2.list-manage.com/subscribe/post?u=${
    import.meta.env.VITE_APP_MAILCHIMP_U
  }&id=${mailChimpId}`

  return (
    <MailchimpSubscribe
      url={chimpUrl}
      render={({ subscribe, status, message }) => (
        <Form
          status={status}
          message={message}
          onValidated={(formData: EmailFormFields) => subscribe(formData)}
          darkStyle={darkStyle}
        />
      )}
    />
  )
}

export default withMailChimpContainer
