import { useState, useEffect } from "react"
import { shortenAddress } from "utils"
import { useThePoolz, useSidNameForAddress } from "@poolzfinance/reacthelper"

export const AccountInfo = () => {
  const [chainMainCoin, setChainInfo] = useState("")
  const thePoolz = useThePoolz()
  const { account, balance, chainId } = thePoolz
  const userMainCoinBalance = parseFloat(thePoolz.web3.utils.fromWei(balance)).toFixed(4)
  const [lookup, { data: SidName }] = useSidNameForAddress()

  useEffect(() => {
    if (!account) return
    const initChainInfo = async () => {
      const chainInfo = await thePoolz.getChaincoinInfo()
      if (chainInfo) setChainInfo(chainInfo.nativeCurrency?.symbol ?? chainInfo.chain)
    }
    initChainInfo()
    lookup(account)
  }, [account, chainId, lookup])

  if (!account) return null
  return (
    <div
      className="flex border-[#f0f0f1] w-56 rounded-md h-[2.1rem] items-center border-[1px] border-solid divide-x accountInfo text-xs"
      data-testid="account-info">
      <div className="truncate basis-1/2 text-center">
        {userMainCoinBalance} {chainMainCoin}
      </div>
      <div className="truncate basis-1/2 text-center">{SidName ?? shortenAddress(account)}</div>
    </div>
  )
}
