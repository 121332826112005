import { useEffect, useState } from "react"
import usePrevious from "hooks/usePrevious"
import { ApplicationModal } from "state/application/actions"
import { useModalOpen, useWalletModalToggle } from "state/application/hooks"
import ArrowRight from "assets/images/arrow-right.svg"
import Metamask from "assets/DesignV3/metamask.png"
import Coinbase from "assets/DesignV3/Coinbase.svg"
import Trustw from "assets/DesignV3/trustw.svg"
import { useThePoolz, useConnectWallet } from "@poolzfinance/reacthelper"
import Loader from "components/Loader"
import ModalWindow from "components/ModalWindow/ModalWindow"
import { useLazyMyRole } from "@poolzfinance/api4"

interface RequestError {
  code?: number
}

function isRequestError(x: unknown): x is RequestError {
  if (x && typeof x === "object" && "code" in x) {
    return true
  }
  return false
}

const WalletModal = () => {
  const thePoolz = useThePoolz()
  const { account } = thePoolz
  const [walletView, setWalletView] = useState("")

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)

  const [loadMyRole] = useLazyMyRole()

  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
      loadMyRole()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen, loadMyRole])

  useEffect(() => {
    if (walletModalOpen) {
      setWalletView("")
    }
  }, [walletModalOpen])

  const {
    isMetaMask,
    isCoinbaseWallet,
    isTrustWallet,
    connectMetamask,
    connectCoinbaseWallet,
    connectTrustWallet,
    isMultipleWallets
  } = useConnectWallet()

  return (
    <ModalWindow isOpen={walletModalOpen} closeModal={toggleWalletModal} parentSelector="#root">
      <div data-testid="wallet-modal-id" className="flex flex-col flex-nowrap w-full md:min-w-[450px]">
        <div className="relative p-4 flex flex-col gap-y-8">
          <div>
            <span className="text-[2rem] text-transparent bg-clip-text bg-gradient-to-r from-[#0666EA] to-[#FB71AE]">
              Connect
            </span>
          </div>
          <div data-testid="option-grid-id" className="flex flex-col gap-y-2 w-full">
            {isMultipleWallets && (
              <div className="bg-[#c74040] text-white rounded-xl p-4">You have multiple wallets installed</div>
            )}
            <button
              className="flex flex-row justify-between items-center rounded-md bg-[#010013] h-14 px-4 w-full"
              onClick={async () => {
                setWalletView("METAMASK")
                try {
                  await connectMetamask()
                } catch (error) {
                  if (isRequestError(error)) {
                    if (!error.code || error.code === 4001) toggleWalletModal()
                  }
                }
              }}>
              <div className="text-white">{!isMetaMask && "Install "}MetaMask</div>
              <div>
                {walletView === "METAMASK" ? <Loader /> : <img src={Metamask} alt={"Icon"} className="w-6 h-6" />}
              </div>
            </button>
            <button
              className="flex flex-row justify-between items-center rounded-md bg-[#010013] h-14 px-4 w-full"
              onClick={async () => {
                setWalletView("Coinbase")

                try {
                  await connectCoinbaseWallet()
                } catch (error) {
                  if (isRequestError(error)) {
                    if (!error.code || error.code === 4001) toggleWalletModal()
                  }
                }
              }}>
              <div className="text-white">{!isCoinbaseWallet && "Install "}Coinbase Wallet</div>
              <div>
                {walletView === "Coinbase" ? <Loader /> : <img src={Coinbase} alt={"Icon"} className="w-6 h-6" />}
              </div>
            </button>
            <button
              className="flex flex-row justify-between items-center rounded-md bg-[#010013] h-14 px-4 w-full"
              onClick={async () => {
                setWalletView("TrustWallet")

                try {
                  await connectTrustWallet()
                } catch (error) {
                  if (isRequestError(error)) {
                    if (!error.code || error.code === 4001) toggleWalletModal()
                  }
                }
              }}>
              <div className="text-white">{!isTrustWallet && "Install "}Trust Wallet</div>
              <div>
                {walletView === "TrustWallet" ? <Loader /> : <img src={Trustw} alt={"Icon"} className="w-6 h-6" />}
              </div>
            </button>
          </div>
          <div data-testid="external-wallet-link-id" className="flex justify-between items-center">
            <div className="text-[11px] text-[#9e9cba]">First time setting up a wallet?</div>
            <a href="https://ethereum.org/wallets/" target="_blank" rel="noopener noreferrer">
              <img src={ArrowRight} alt="Arrow" />
            </a>
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default WalletModal
