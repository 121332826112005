export const navItemsData = [
  {
    path: "/leaderboard",
    text: "Leaderboard"
  },
  {
    path: "/dashboard/MyTokens",
    text: "Dashboard"
  },
  {
    path: "/lock-poolx",
    text: "Lock $POOLX"
  },
  {
    path: "/boost",
    text: "Poolz Boost"
  }
]
