import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { ThePoolzProvider, useTheSiwe, useThePoolz } from "@poolzfinance/reacthelper"
import { API4Provider } from "@poolzfinance/api4"
import ScrollToTop from "components/ScrollUp/ScrollUp"
import BtnUp from "components/BtnUp/BtnUp"
import WalletModal from "components/WalletModal"
import { TimeWarning } from "components/Header/components/TimeWarning/TimeWarning"
import { routes } from "./routes"
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"

const API4Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { account } = useThePoolz()
  const { signInWithEthereum } = useTheSiwe(() => ({
    Domain: import.meta.env.VITE_APP_SIWE_DOMAIN,
    URI: import.meta.env.VITE_APP_SIWE_URI,
    ExpirationAt: new Date(
      new Date().getTime() + 1000 * 60 * 60 * Number(import.meta.env.VITE_APP_SIWE_EXPIRATION_HOURS)
    ).toISOString()
  }))

  return (
    <API4Provider
      account={account}
      options={{ siweCallBack: signInWithEthereum, uri: import.meta.env.VITE_APP_API4_GRAPHQL_URL }}>
      {children}
    </API4Provider>
  )
}

export default function App() {
  return (
    <Suspense fallback={null}>
      <ThePoolzProvider>
        <ErrorBoundary>
          <API4Wrapper>
            <ScrollToTop />
            <TimeWarning />
            <WalletModal />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <>
                      <Header darkStyle={route.darkStyle} />
                      <div className="overflow-hidden flex-auto ">
                        <route.component />
                      </div>
                      <Footer darkStyle={route.darkStyle} />
                    </>
                  }
                />
              ))}
            </Routes>
            <BtnUp />
          </API4Wrapper>
        </ErrorBoundary>
      </ThePoolzProvider>
    </Suspense>
  )
}
