import Ventures from "assets/DesignV3/Ventures.svg"
import VenturesActive from "assets/DesignV3/VenturesActive.svg"
import Boutique from "assets/DesignV3/Boutiq.svg"
import BoutiqueActive from "assets/DesignV3/BoutiqActive.svg"
import Incubation from "assets/DesignV3/Incubation.svg"
import IncubationActive from "assets/DesignV3/IncubationActive.svg"
import { DropdownObjectType } from "./types"

export const DropdownMenuObjects: DropdownObjectType[] = [
  {
    image: Incubation,
    imageActive: IncubationActive,
    text: "Poolz Incubation",
    url: "/grants"
  },
  {
    image: Ventures,
    imageActive: VenturesActive,
    text: "Poolz Ventures",
    url: "https://ventures.poolz.finance/"
  },
  {
    image: Boutique,
    imageActive: BoutiqueActive,
    text: "Poolz Boutique",
    url: "https://boutique.poolz.finance"
  },
  {
    image: Incubation,
    imageActive: IncubationActive,
    text: "Poolz Boost",
    url: "https://forms.gle/ui6C4eYW6qE2mmoY7"
  }
]
