interface LoggerProps {
  [k: string]: string | number
}

const Logger = async (errorMessage: LoggerProps): Promise<void> => {
  if (~["test", "development"].indexOf(import.meta.env.MODE)) return
  let errorMessageString = ""

  for (const [key, value] of Object.entries(errorMessage)) {
    errorMessageString = errorMessageString + `**${key}**: ${value}\n`
  }

  const params = {
    content: errorMessageString
  }
  await fetch(`${import.meta.env.VITE_APP_API3}monitor`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    body: JSON.stringify(params)
  })
}

export default Logger
